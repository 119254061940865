import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CircularProgressWithLabel({ ...props }) {
  let Text;
  let value;
  if (props.type === "speed") {
    Text = `${Math.round(props.circlevalue * 10) / 10} MPH`;
    value = Math.round((100 / 8) * props.circlevalue); //max speed is 6
  } else if (props.type === "VAC") {
      Text = `${Math.round(props.circlevalue * 10) / 10} inH₂O`;
      value = Math.round((100 / -30) * props.circlevalue); //max speed is 6
  } else if (props.type === "percent") {
      Text = `${Math.round(props.circlevalue)}%`;
      value = Math.round(props.circlevalue);
  } else if (props.type === "string") {
      Text = Math.round(props.circlevalue);
      value = 100;
  } else if (props.type === "etc") {
      Text = `${props.circlevalue} MIN`;
      value = 100;
  } else {
      Text = props.circlevalue;
      value = 100;
  }

  return (
      <Box
          sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: "5px 10px",
          }}
      >
          <Box
              sx={{
                  position: "relative",
                  display: "inline-flex",
              }}
          >
              <CircularProgress
                  value={value}
                  variant="determinate"
                  {...props}
                  fontSize="small"
                  thickness={5}
              />
              <Box
                  sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                  }}
              >
                  <Typography
                      sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          padding: "10px 10px",
                          margin: "5px",
                          minWidth: "90px",
                      }}
                      align="center"
                      // variant="h5"
                      component="div"
                      color="text.primary"
                  >
                      {Text}
                  </Typography>
              </Box>
          </Box>
          <Typography
              sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
              }}
              variant="h5"
              color="text.primary"
          >
              {props.caption}
          </Typography>
      </Box>
  );
}
