import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, useTheme, TextField, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import Header from "../../components/Header";
import Map from "../../components/Map";
import KeyNav from "../../components/KeyNav";
import Topbar from "../global/Topbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import VncViewer from "../../components/VncViewer";
import StatusWithLabel from "../../components/Status";
// import ObjectDetectionVisualizer from "object-detection-visualizer";
import CircularProgressWithLabel from "../../components/CircularProgress";
import LinearGauge from "../../components/linearGauge";
import GaugeComponent from "react-gauge-component";
import BatteryGauge from "react-battery-gauge";
import staticImage from "../../data/farm.jpg";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FlexibleTable from "../../components/FlexibleTable";
import { OpenWithTwoTone } from "@mui/icons-material";

const Dashboard = ({
  zedObjects,
  image360,
  image,
  handleKeyCode,
  handleComebackHome,
  handleRemoveABLines,
  handleKillRos,
  pointB,
  pointA,
  handleSetHome,
  home,
  pointBSelected,
  handlePointB,
  pointASelected,
  handlePointA,
  isToolBarDown,
  toolBarIndicatorDown,
  isVacuumOn,
  handleToolBar,
  handleVacuum,
  handleSteer,
  isMissionLaunch,
  handleMission,
  isPreMissionLaunch,
  handlePreMission,
  isAutonomousNavigation,
  handleStartNavigation,
  handleStopNavigation,
//   isBraked,
//   handleBrake,
  handleIp,
  isConnected,
  toggleConnection,
  temperature,
  singulations,
  populations,
  loadpins,
  current,
  location,
  odom,
  odomOrigin,
  homeLocation,
  globalPath,
  geoFence,
  gpsStatus,
  battery,
  barAngle,
  vacPrs,
  vacOffIndicator,
  batteryAux,
  ultraSound,
  pneumatic, 
  etc,
  progress,
  speed,
  handleVNC,
  handlePose
}) => {


    // console.log('vacPrs: ', vacPrs)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [openPose, setOpenPose] = React.useState(false);
    const [openHome, setOpenHome] = React.useState(false);
    const [openToolBar, setOpenToolBar] = React.useState(false);
    const [openSteer, setOpenSteer] = React.useState(false);

    const [isImage360, setIsImage360] = React.useState(false);
    const [boundingBox, setBoundingBox] = React.useState([]);

    const [xLocation, setXLocation] = useState("");
    const [yLocation, setYLocation] = useState("");
    const [selectedSteer, setSelectedSteer] = useState('');
    const [selectedIp, setSelectedIp] = useState('');

    const videoRef = useRef(null);

    const ipOptions = [
        'ws://v2:9090',
        'ws://v3:9090',
        'ws://gpu-pc:9090',
        'ws://0.0.0.0:9090',
        'ws://localhost:9090',
    ];

    // const [comeBackHomeConservative, setComeBackHomeConservative] =
    //     React.useState(false);

    // const canvasRef = useRef(null);

    // const ref = useRef();

    const handleOpenHome = () => {
        setOpenHome(true);
    };

    const handleOpenPose = () => {
        setOpenPose(true);
    };

    const handleCloseHome = () => {
        setOpenHome(false);
    };

    const handleClosePose = () => {
        setOpenPose(false);
    };

    const handleHomeActionNo = () => {
        setOpenHome(false);
    };

    const handleHomeActionYesConservative = () => {
        handleComebackHome(true);
        setOpenHome(false);
    };

    const handleHomeActionYesNonConservative = () => {
        handleComebackHome(false);
        setOpenHome(false);
    };

    const handleSave = () => {
        handlePose(xLocation, yLocation);
        setOpenPose(false);
    };

    const handleImageClick = () => {
        setIsImage360((prev) => !prev);
    };

    useEffect(() => {
        const objects = [];
        for (const obj of zedObjects) {
            objects.push({
                label: obj.label,
                confidence: obj.confidence,
                coordinates: {
                    width:
                        obj.bounding_box_2d.corners[1].kp[0] -
                        obj.bounding_box_2d.corners[0].kp[0],
                    height:
                        obj.bounding_box_2d.corners[2].kp[1] -
                        obj.bounding_box_2d.corners[1].kp[1],
                    x: obj.bounding_box_2d.corners[0].kp[0],
                    y: obj.bounding_box_2d.corners[0].kp[1],
                },
            });
        }
        setBoundingBox(objects);
    }, [zedObjects]);
   
    const vescColumns = [
        { id: 'value', label: '' },
        { id: 'RL', label: 'RL' },
        { id: 'RR', label: 'RR' },
        { id: 'FL', label: 'FL' },
        { id: 'FR', label: 'FR' },
      ];
      
      const vescData = [
        { value: 'Temperature (°C)', RL: temperature.RL, RR: temperature.RR, FL: temperature.FL, FR: temperature.FR },
        { value: 'Current (A)', RL: current.RL, RR: current.RR, FL: current.FL, FR: current.FR },
      ];
    

      const rowUnitColumns = [
        { id: 'value', label: '' },
        { id: 'row1', label: 'row1' },
        { id: 'row2', label: 'row2' },
        { id: 'row3', label: 'row3' },
        { id: 'row4', label: 'row4' },
        ]
    

      const rowUnitData = [
        { value: 'Singulation (%)', row1: singulations[0], row2: singulations[1], row3: singulations[2], row4: singulations[3] },
        { value: 'Population (K)', row1: populations[0], row2: populations[1], row3: populations[2], row4: populations[3] },
        { value: 'Down Force (lbs)', row1: loadpins[0], row2: loadpins[1], row3: loadpins[2], row4: loadpins[3] },

      ];

      
      return (
        <Box 
        m="10px"
        >
            {/* HEADER */}
            <Box
                mb="1"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // height="1hv"
                // flexDirection={{ xs: "column", sm: "row" }} // Stack items on small screens, align in a row on larger screens
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // flexBasis={{ xs: "100%", sm: "auto" }} // Allow the box to grow on larger screens
                >
                    <img
                        alt="profile-user"
                        width="75px"
                        height="75px"
                        src={`../../assets/logo.svg`}
                        style={{ cursor: "pointer", borderRadius: "100%" }}
                    />
                    <Header title="MONITORING DASHBOARD" subtitle="" />
                    <Topbar />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // mt={{ xs: "20px", sm: "0" }} // Add margin-top only on small screens
                >
                    <TextField
                        select
                        sx={{
                            backgroundColor: isConnected
                                ? colors.greenAccent[700]
                                : colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            m: "0 5px 0 0",
                            borderRadius: `5px 5px 5px 5px`,
                            minWidth: "200px",
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="outlined-basic"
                        label="ROBOT IP"
                        variant="filled"
                        size="small"
                        value={selectedIp}
                        onChange={(event) => {
                            setSelectedIp(event.target.value);
                            handleIp(event);
                        }}
                    >
                        {ipOptions.map((ip) => (
                            <MenuItem key={ip} value={ip}>
                                {ip}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button
                        sx={{
                            backgroundColor: isConnected
                                ? colors.greenAccent[700]
                                : colors.redAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 10px",
                            m: "0 5px 0 0",
                        }}
                        onClick={() => toggleConnection()}
                    >
                        <LeakAddIcon sx={{ mr: "10px" }} />
                        {isConnected ? "CONNECTED" : "CONNECT TO ROBOT"}
                    </Button>
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box sx={{ width: '100%', height: '92vh', padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            {/* Outer container */}
            <Box sx={{ display: 'flex', height: '100%', gap: 1 }}>
                {/* Left column with 3/4 width */}
                <Box sx={{ width: '70%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ height: '66.66%', bgcolor: colors.primary[400], overflow:"hidden", height:"100%", width:"100%" }}> 
                        <Map
                            location={location}
                            pointA={pointA}
                            pointB={pointB}
                            odom={odom}
                            odomOrigin={odomOrigin}
                            homeLocation={homeLocation}
                            globalPath={globalPath}
                            geoFence={geoFence}
                        />
                    </Box>
                    <Box sx={{ height: '33.33%' }}>
                        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <Box sx={{ width: '50%', paddingRight: '10px', bgcolor: colors.primary[400], display: 'flex', flexDirection: 'row',flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }} >
                            <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                             >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={10}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 2.5,
                                            color: "#5BE12C",
                                        },
                                        {
                                            limit: 5,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 7.5,
                                            color: "orange",
                                        },
                                        {
                                            limit: 10,
                                            color: "red",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " MPH",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={
                                    isConnected
                                        ? parseFloat(
                                              Math.round(speed * 10) / 10
                                          ).toFixed(1)
                                        : 0
                                }
                            />
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                SPEED
                            </Typography>
                            </div>
                            <CircularProgressWithLabel
                                color={
                                    progress <= 30
                                        ? "error"
                                        : progress <= 65
                                        ? "warning"
                                        : "success"
                                }
                                circlevalue={isConnected ? progress : 0}
                                size="5rem"
                                caption="PROGRESS"
                                type="percent"
                            />
                            <BatteryGauge
                                value={batteryAux}
                                orientation="vertical"
                                size={100}
                                padding={0}
                                maxValue={100}
                                customization={{
                                    batteryBody: {
                                        strokeWidth: 2,
                                        cornerRadius: 6,
                                        fill: "none",
                                        strokeColor: "#fff",
                                    },
                                    batteryCap: {
                                        fill: "none",
                                        strokeWidth: 2,
                                        strokeColor: "#fff",
                                        cornerRadius: 2,
                                        capToBodyRatio: 0.4,
                                    },
                                    batteryMeter: {
                                        fill: "green",
                                        lowBatteryValue: 11,
                                        lowBatteryFill: "red",
                                        outerGap: 1,
                                        noOfCells: 10, // more than 1, will create cell battery
                                        interCellsGap: 1,
                                    },
                                    readingText: {
                                        lightContrastColor: "#fff",
                                        darkContrastColor: "#fff",
                                        lowBatteryColor: "red",
                                        fontFamily: "Helvetica",
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        showPercentage: false,
                                    },
                                }}
                            />

                            <BatteryGauge
                                value={battery}
                                orientation="vertical"
                                size={100}
                                padding={0}
                                customization={{
                                    batteryBody: {
                                        strokeWidth: 2,
                                        cornerRadius: 6,
                                        fill: "none",
                                        strokeColor: "#fff",
                                    },
                                    batteryCap: {
                                        fill: "none",
                                        strokeWidth: 2,
                                        strokeColor: "#fff",
                                        cornerRadius: 2,
                                        capToBodyRatio: 0.4,
                                    },
                                    batteryMeter: {
                                        fill: "green",
                                        lowBatteryValue: 20,
                                        lowBatteryFill: "red",
                                        outerGap: 1,
                                        noOfCells: 10, // more than 1, will create cell battery
                                        interCellsGap: 1,
                                    },
                                    readingText: {
                                        lightContrastColor: "#fff",
                                        darkContrastColor: "#fff",
                                        lowBatteryColor: "red",
                                        fontFamily: "Helvetica",
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        showPercentage: true,
                                    },
                                }}
                            />

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <StatusWithLabel
                                    color={
                                        etc <= 60
                                            ? 'green'
                                            : etc <= 120
                                            ? 'yellow'
                                            : 'red'
                                    }
                                    text={isConnected ? etc : 0}
                                    type="ETC"
                                />

                                <StatusWithLabel
                                    color={
                                        gpsStatus === "RTK Fixed"
                                            ? 'green'
                                            : 'red'
                                    }
                                    text={isConnected ? gpsStatus : "No Conn."}
                                    type="GPS"
                                />
                            </div>
                            <FlexibleTable columns={vescColumns} data={vescData} />

                            </Box>
                            <Box sx={{ width: '50%', bgcolor: colors.primary[400],  padding: "10px", display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }}>
                                <Button
                                sx={{
                                    backgroundColor: isPreMissionLaunch
                                        ? colors.greenAccent[700]
                                        : colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                variant="contained"
                                onClick={() => handlePreMission()}
                            >
                                {isPreMissionLaunch ? (
                                    <i
                                        className="fa-solid fa-square-check fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa-solid fa-square fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                )}

                                {isPreMissionLaunch
                                    ? "PRE LAUNCHED"
                                    : "PRE-MISSION"}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: isMissionLaunch
                                        ? colors.greenAccent[700]
                                        : colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                variant="contained"
                                onClick={() => handleMission()}
                            >
                                {isMissionLaunch ? (
                                    <i
                                        className="fa-solid fa-square-check fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa-solid fa-square fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                )}

                                {isMissionLaunch ? "M LAUNCHED" : "LAUNCH M"}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                onClick={() => handleVacuum()}
                            >
                                {!vacOffIndicator ? (
                                    <i
                                        className="fa-solid fa-spin fa-fan fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa-solid fa-fan fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                )}
                                {!vacOffIndicator ? "TURN OFF VAC" : "TURN ON VAC"}
                            </Button>                        
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                onClick={() => handleRemoveABLines()}
                            >
                                {
                                    <i
                                        className="fa-solid fa-trash-can fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                }
                                REMOVE AB
                            </Button>

                            <Button
                                sx={{
                                    backgroundColor: home
                                        ? colors.greenAccent[700]
                                        : colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                onClick={() => handleSetHome()}
                            >
                                {home ? (
                                    <i
                                        className="fa-solid fa-square-check fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa-solid fa-square fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                )}
                                {home ? "HOME SET" : "SET HOME"}
                            </Button>


                            <div style={{ display: "inline-block", flexDirection: "row" }}>

                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    margin: "2.5px",
                                    minWidth: "150px",
                                }}
                                onClick={() => setOpenToolBar(true)}>
                                {toolBarIndicatorDown ? (
                                    <i
                                        className="fa-solid fa-arrows-up-to-line fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa-solid fa-arrows-down-to-line fa-xl"
                                        style={{ marginRight: "0.5em" }}
                                    ></i>
                                )}
                                {toolBarIndicatorDown ? "R TOOLBAR" : "L TOOLBAR"}
                            </Button>
                            <Dialog
                                    open={openToolBar}
                                    onClose={() => setOpenToolBar(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle
                                        id="alert-dialog-title"
                                        sx={{
                                            color: colors.grey[100],
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            padding: "5px 5px",
                                            margin: "2.5px",
                                            minWidth: "120px",
                                        }}
                                    >
                                        {toolBarIndicatorDown ? (
                                            "Are you sure to raise the toolbar?"
                                        ) : (
                                            "Are you sure to lower the toolbar? Confirm row cleaners set to FIELD mode. Confirm wheels are in the skid position."
                                        )}
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={() => setOpenToolBar(false)}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={() => {setOpenToolBar(false); setTimeout(() => {
                                                handleToolBar(); 
                                              }, 1000); }}
                                            autoFocus
                                        >
                                            YES
                                        </Button>
                                    </DialogActions>
                                </Dialog>


                            </div>

                          
                            <div style={{ display: "inline-block", flexDirection: "row" }}>
                                <Button
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        padding: "5px 5px",
                                        margin: "2.5px",
                                        minWidth: "150px",
                                    }}
                                    variant="contained"
                                    onClick={handleOpenHome}
                                >
                                    {
                                        <i
                                            className="fa-solid fa-house-crack fa-xl"
                                            style={{ marginRight: "0.5em" }}
                                        ></i>
                                    }
                                    GO TO HOME
                                </Button>
                                <Dialog
                                    open={openHome}
                                    onClose={handleCloseHome}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle
                                        id="alert-dialog-title"
                                        sx={{
                                            color: colors.grey[100],
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            padding: "5px 5px",
                                            margin: "2.5px",
                                            minWidth: "120px",
                                        }}
                                    >
                                        {
                                            "Are you sure to bring the robot back to the home location?"
                                        }
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText
                                            sx={{ fontSize: "16px" }}
                                            id="alert-dialog-description"
                                        >
                                            This will pause the autonomous
                                            operation
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={handleHomeActionNo}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={
                                                handleHomeActionYesConservative
                                            }
                                            autoFocus
                                        >
                                            YES, Conservative
                                        </Button>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={
                                                handleHomeActionYesNonConservative
                                            }
                                            autoFocus
                                        >
                                            YES, Non-Conservative
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div style={{ display: "inline-block", flexDirection: "row" }}>
                                <Button
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        padding: "5px 5px",
                                        margin: "2.5px",
                                        minWidth: "150px",
                                    }}
                                    variant="contained"
                                    onClick={handleOpenPose}
                                >
                                    {
                                        <i
                                            className="fa-solid fa-gear fa-xl"
                                            style={{ marginRight: "0.5em" }}
                                        ></i>
                                    }
                                    GO TO POSE
                                </Button>
                                <Dialog
                                    open={openPose}
                                    onClose={handleClosePose}
                                    aria-labelledby="alert-dialog-title1"
                                    aria-describedby="alert-dialog-description1"
                                >
                                    <DialogTitle
                                        id="alert-dialog-title1"
                                        sx={{
                                            color: colors.grey[100],
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            padding: "5px 5px",
                                            margin: "2.5px",
                                            minWidth: "120px",
                                        }}
                                    >
                                        Specify the coordinate of the location
                                        you want to send the robot to?
                                    </DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="xLocation"
                                            label="X Location"
                                            type="number"
                                            fullWidth
                                            value={xLocation}
                                            onChange={(event) =>
                                                setXLocation(event.target.value)
                                            }
                                        />
                                        <TextField
                                            margin="dense"
                                            id="yLocation"
                                            label="Y Location"
                                            type="number"
                                            fullWidth
                                            value={yLocation}
                                            onChange={(event) =>
                                                setYLocation(event.target.value)
                                            }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "170px",
                                            }}
                                            onClick={handleSave}
                                        >
                                            SAVE
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            {/* <Button
                                sx={{
                                    border: "1px solid",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 0px",
                                    margin: "2.5px",
                                    maxWidth: "150px",
                                    lineHeight: "1",
                                }}
                                //   variant="outlined"
                                color="error"
                                variant="outlined"
                                onClick={() => handleAutonomousNavigation()}
                            >
                                {isAutonomousNavigation
                                    ? "Enable Manual Navigation"
                                    : "Start Autonomous navigation"}
                            </Button> */}
                            <div>
                            <Button
                                sx={{
                                    backgroundColor: isAutonomousNavigation === true
                                    ? colors.greenAccent[700]
                                    : colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "30px 0px",
                                    margin: "2.5px",
                                }}
                                variant="outlined"
                                onClick={() => handleStartNavigation()}
                                >
                                    { isAutonomousNavigation === true ? <i  className="fa-solid fa-circle-play fa-2xl"> </i> : <i  className="fa-solid fa-circle-play fa-2xl" > </i
                                >}
                            
                            </Button>
                            
                            <Button
                                sx={{
                                    backgroundColor: isAutonomousNavigation === false
                                    ? colors.greenAccent[700]
                                    : colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    padding: "30px 0px",
                                    margin: "2.5px",
                                }}
                                variant="outlined"
                                onClick={() => handleStopNavigation()}
                            >
                                    { isAutonomousNavigation === false ? <i  className="fa-solid fa-circle-pause fa-2xl" > </i> : <i  className="fa-solid fa-circle-pause fa-2xl" > </i
                                    >}
                            </Button>
                            </div>
                            <FormControl
                                sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                padding: "2px 5px",
                                borderRadius: "5px",
                            }}>
                                <FormLabel id="demo-row-radio-buttons-group-label"><Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>STEERING</Typography></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(event) => {setSelectedSteer(event.target.value); setOpenSteer(true)}}

                                >
                                    <FormControlLabel value="skid" control={<Radio />} label={<Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>S</Typography>} />
                                    <FormControlLabel value="pivit" control={<Radio />} label={<Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>P</Typography>} />
                                    <FormControlLabel value="transport" control={<Radio />} label={<Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>T</Typography>} />

                                    <Dialog
                                    open={openSteer}
                                    onClose={() => setOpenSteer(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle
                                        id="alert-dialog-title"
                                        sx={{
                                            color: colors.grey[100],
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            padding: "5px 5px",
                                            margin: "2.5px",
                                            minWidth: "120px",
                                        }}
                                    >
                                        {selectedSteer === 'skid' ? (
                                            "Confirm row cleaners set to SKID mode before setting wheels to SKID position."
                                        ) : selectedSteer === 'transport' ? (
                                            "Confirm row cleaners set to TRANSPORT mode before setting wheels to TRANSPORT position."
                                        ) : ( "Confirm toolbar is in the CLEAR position before setting wheels to PIVOT position." )}
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={() => {setOpenSteer(false); setTimeout(() => {
                                                handleSteer(selectedSteer); 
                                              }, 1000); }}
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                        <Button
                                            sx={{
                                                color: colors.grey[100],
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "5px 5px",
                                                margin: "2.5px",
                                                minWidth: "120px",
                                            }}
                                            onClick={() => setOpenSteer(false)}
                                            autoFocus
                                        >
                                            CLOSE
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                </RadioGroup>
                            </FormControl>
                            <KeyNav
                                getKeyCode={handleKeyCode}
                                isAutonomousNavigation={isAutonomousNavigation}
                                
                            />
                            <Button
                                sx={{
                                    border: "1px solid",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 10px",
                                    margin: "2.5px",
                                    minWidth: "145px",
                                }}
                                color="error"
                                variant="outlined"
                                onClick={() => handleKillRos()}
                            >
                                Kill ROBOT
                            </Button>

                            </Box>

                        </Box>
                    </Box>
                </Box>

                {/* Right column with 1/4 width */}
                <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* Three rows in the right column, each with 1/3 height */}
                    <Box sx={{ height: '40%', bgcolor: colors.primary[400], display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }} >
                              <img style={{width: '100%', height: '100%', objectFit: 'cover', rotate: '180deg'}} src="http://192.168.7.12/api/v2/camera/stream"/>
                        </Box>
                    <Box sx={{ height: '60%', bgcolor: colors.primary[400], display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }} >
                        <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={200}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 50,
                                            color: "red",
                                        },
                                        {
                                            limit: 100,
                                            color: "orange",
                                        },
                                        {
                                            limit: 150,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 200,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " psi",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={
                                    isConnected
                                        ? parseFloat(
                                              Math.round(pneumatic * 10) / 10
                                          ).toFixed(1)
                                        : 0
                                }
                            />
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                Pneumatic
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={100}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 25,
                                            color: "red",
                                        },
                                        {
                                            limit: 50,
                                            color: "orange",
                                        },
                                        {
                                            limit: 75,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 100,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + "°",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={
                                    isConnected
                                        ? parseFloat(
                                              Math.round(barAngle * 10) / 10
                                          ).toFixed(1)
                                        : 0
                                }
                            />
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                Bar Angle
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={100}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 25,
                                            color: "red",
                                        },
                                        {
                                            limit: 50,
                                            color: "orange",
                                        },
                                        {
                                            limit: 75,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 100,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " %",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={
                                    isConnected
                                        ? parseFloat(
                                              Math.round(ultraSound * 10) / 10
                                          ).toFixed(1)
                                        : 0
                                }
                            />
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                SEED BOX
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={30}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 7.5,
                                            color: "red",
                                        },
                                        {
                                            limit: 15,
                                            color: "orange",
                                        },
                                        {
                                            limit: 22.5,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 30,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " inH₂O",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={isConnected ? Math.round(vacPrs) : '0'}
                            />

                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                VAC PRS
                            </Typography>
                        </div>
                        
                        {/* <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={100}
                                maxValue={140}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 110,
                                            color: "red",
                                        },
                                        {
                                            limit: 120,
                                            color: "orange",
                                        },
                                        {
                                            limit: 130,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 140,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " K",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={isConnected ? Math.round(vacPrs) : '0'}
                            />

                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                Population
                            </Typography>
                        </div> */}
                        {/* <div
                            style={{
                                display: "inline-block",
                                width: "180px",
                                height: "auto",
                                // marginTop: "-20px",
                                // marginLeft: "-35px",
                            }}
                        >
                            <GaugeComponent
                                type="semicircle"
                                minValue={0}
                                maxValue={100}
                                // marginInPercent={.0}
                                id="gauge-component4"
                                arc={{
                                    padding: 0,
                                    subArcs: [
                                        {
                                            limit: 90,
                                            color: "red",
                                        },
                                        {
                                            limit: 95,
                                            color: "orange",
                                        },
                                        {
                                            limit: 98,
                                            color: "yellow",
                                        },
                                        {
                                            limit: 100,
                                            color: "#5BE12C",
                                        },
                                    ],
                                }}
                                labels={{
                                    valueLabel: {
                                        formatTextValue: (value) =>
                                            value + " %",
                                        style: {
                                            fontSize: "50px",
                                            fontWeight: "bold",
                                        },
                                    },
                                    tickLabels: {
                                        hideMinMax: true,
                                    },
                                }}
                                pointer={{
                                    type: "blob",
                                    elastic: true,
                                    width: 30,
                                }}
                                value={isConnected ? Math.round(vacPrs) : '0'}
                            />

                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center", // ensure text alignment is center
                                    marginTop: "-20px",
                                }}
                                variant="h5"
                                color="text.primary"
                            >
                                Singulation
                            </Typography>
                        </div> */}
                        <StatusWithLabel
                                    color={
                                        isConnected ? 'green'
                                        : 'red'
                                    }
                                    text={isConnected ? "PLANTING" : "No Conn."}
                                    type="PLANTING STATUS"
                                />
                        <FlexibleTable columns={rowUnitColumns} data={rowUnitData} />
                    </Box>
                    {/* <Box sx={{ height: '20%' }}>   */}
                        {/* <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}> */}
                            {/* <Box sx={{ width: '50%', bgcolor: colors.primary[400], display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"  }}> */}
                            
                            {/* <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                pl: "10px",
                            }}
                            variant="h5"
                            color="text.primary"
                        >
                            TEMPERATURE
                        </Typography> */}
                        {/* {Object.keys(temperature).length > 0 && (
                            <LinearGauge
                                value={temperature.RL}
                                caption="RL"
                                type="temperature"
                                minValue={0}
                                maxValue={100}
                            />
                        )}
                        {Object.keys(temperature).length > 0 && (
                            <LinearGauge
                                value={temperature.RR}
                                caption="RR"
                                type="temperature"
                                minValue={0}
                                maxValue={100}
                            />
                        )}
                        {Object.keys(temperature).length > 0 && (
                            <LinearGauge
                                value={temperature.FL}
                                caption="FL"
                                type="temperature"
                                minValue={0}
                                maxValue={100}
                            />
                        )}
                        {Object.keys(temperature).length > 0 && (
                            <LinearGauge
                                value={temperature.FR}
                                caption="FR"
                                type="temperature"
                                minValue={0}
                                maxValue={100}
                            />
                        )} */}
                            {/* </Box> */}
                            {/* <Box sx={{ width: '50%', bgcolor: colors.primary[400], display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"  }}> */}
                            {/* <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                pl: "10px",
                            }}
                            variant="h5"
                            color="text.primary"
                        >
                            CURRENT
                        </Typography>
                        {Object.keys(current).length > 0 && (
                            <LinearGauge
                                value={current.RL}
                                caption="RL"
                                type="current"
                                minValue={0}
                                maxValue={400}
                            />
                        )}
                        {Object.keys(current).length > 0 && (
                            <LinearGauge
                                value={current.RR}
                                caption="RR"
                                type="current"
                                minValue={0}
                                maxValue={400}
                            />
                        )}
                        {Object.keys(current).length > 0 && (
                            <LinearGauge
                                value={current.FL}
                                caption="FL"
                                type="current"
                                minValue={0}
                                maxValue={400}
                            />
                        )}
                        {Object.keys(current).length > 0 && (
                            <LinearGauge
                                value={current.FR}
                                caption="FR"
                                type="current"
                                minValue={0}
                                maxValue={400}
                            />
                        )} */}
                            {/* </Box> */}

                        {/* </Box> */}
                    {/* </Box> */}
                </Box>
            </Box>
    </Box>
    </Box>
  );
};

export default Dashboard;










