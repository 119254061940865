import React from 'react';
import { ROS } from "./components/ROS";
import ToggleConnect from "./components/ToggleConnect";
import Subscriber from "./components/Subscriber";

function App() {
  return (
    <ROS>
      {/* <ToggleConnect /> */}
      <Subscriber />
    </ROS>
  );
}

export default App;
