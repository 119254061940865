import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useROS, ROS } from "./ROS";
import { Routes, Route } from "react-router-dom";
// import Sidebar from "../scenes/global/Sidebar";
import Dashboard from "../scenes/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../theme";

const EchoTopic = () => {
    const [theme, colorMode] = useMode();
    // const [isSidebar, setIsSidebar] = useState(false);
    
    const handleMessage = useCallback((topicName, setStateFunction) => (message) => {
        setStateFunction(message.data);
      }, []);

    const {
        createListener,
        createPublisher,
        createMessage,
        createSrv,
        createService,
    } = useROS();

    const [batteryMsg, setBatteryMsg] = useState([]);
    const [batteryAuxMsg, setBatteryAuxMsg] = useState([]);
    const [ultraSoundMsg, setUltraSoundMsg] = useState([]);
    const [vacPrs, setVacPrsMsg] = useState([]);
    const [vacOffIndicator, setVacOffIndicator] = useState([]);
    const [pneumatic, setPneumaticMsg] = useState([]);
    const [barAngle, setBarAngleMsg] = useState([]);
    const [etcMsg, setEtcMsg] = useState([]);
    const [progressMsg, setProgressMsg] = useState([]);

    const [gpsStatusMsg, setGpsStatusMsg] = useState();
    const [gpsMsg, setGpsMsg] = useState([]);
    // const gpsMsgRef = useRef(null);

    // odom
    const [odomMsg, setOdomMsg] = useState([]);

    // odom origin
    const [odomOriginMsg, setOdomOriginMsg] = useState([]);

    // home location
    const [homeLocationMsg, setHomeLocationMsg] = useState([]);

    // global path
    const [globalPathMsg, setGlobalPathMsg] = useState({});

    // geo fence
    const [geoFenceMsg, setGeoFenceMsg] = useState({});

    // 2020
    const [singulations, setSingulation] = useState([]);
    const [populations, setPopulation] = useState([]);
    const [loadPins, setLoadPins] = useState([]);
    
    // rr
    const [rrSensorTemp, setRrSensorTemp] = useState([]);
    const [rrSensorCur, setRrSensorCur] = useState([]);
    // rl
    const [rlSensorTemp, setRlSensorTemp] = useState([]);
    const [rlSensorCur, setRlSensorCur] = useState([]);
    // fl
    const [flSensorTemp, setFlSensorTemp] = useState([]);
    const [flSensorCur, setFlSensorCur] = useState([]);
    // fr
    const [frSensorTemp, setFrSensorTemp] = useState([]);
    const [frSensorCur, setFrSensorCur] = useState([]);

    // const [isBraked, setIsBraked] = useState(false);

    const [isAutonomousNavigation, setIsAutonomousNavigation] = useState(null);

    const [isPreMissionLaunch, setIsPreMissionLaunch] = useState(false);
    const [isMissionLaunch, setIsMissionLaunch] = useState(false);

    const [isToolBarDown, setIsToolBarDown] = useState(null);
    const [toolBarIndicatorDown, setToolBarIndicatorDown] = useState(null);
    const [isVacuumOn, setIsVacuumOn] = useState(null);
    const [steer, setSteer] = useState(null);

    const [pointASelected, setPointASelected] = useState(false);
    const [pointBSelected, setPointBSelected] = useState(false);

    const [pointAGpsMsg, setPointAGpsMsg] = useState(false);
    const [pointBGpsMsg, setPointBGpsMsg] = useState(false);
    const [home, setHome] = useState(false);

    const [randomPose, setRandomPose] = useState(false);

    const [PoseX, setPoseX] = useState();
    const [PoseY, setPoseY] = useState();

    const [image, setImage] = useState([]);

    const [zedObjects, setzedObjects] = useState([]);

    const [image360, setImage360] = useState([]);

    const mounted = useRef(false); // useRef to keep track of the component mount status
    const { isConnected, url, changeUrl, toggleConnection, toggleAutoconnect } = useROS();

    const setterRef = useRef({});


    // Try replacing this with your own unique rosbridge webserver address
    const defaultURL = "";


    useEffect(() => {
        setterRef.current = {
        setImage, setRrSensorTemp, setRrSensorCur, setRlSensorTemp, setRlSensorCur, setFlSensorTemp, setFlSensorCur, setFrSensorTemp, setFrSensorCur, setGpsMsg, setGlobalPathMsg, setGeoFenceMsg, setOdomMsg, setOdomOriginMsg, setHomeLocationMsg, setBatteryMsg, setPneumaticMsg, setUltraSoundMsg, setEtcMsg, setProgressMsg, setGpsStatusMsg, setzedObjects, setImage360
        };
    }, []);

    // only runs once when ToggleConnect is first rendered (mounted)
    useEffect(() => {
        console.log("ToggleConnect is mounted!");
        if (url !== defaultURL) {
            changeUrl(defaultURL);
        }

        if (!isConnected) {
            toggleAutoconnect();
        }
    }, []);



    
        const listeners = useMemo(() => [
          { topic: "/zed2i/zed_node/rgb/image_rect_color/compressed", messageType: "sensor_msgs/CompressedImage", handler: (msg) => setterRef.current.setImage("data:image/jpg;base64," + msg.data) },
          { topic: "/r_right_wheel/sensors/core", messageType: "vesc_msgs/VescStateStamped", handler: (msg) => { setterRef.current.setRrSensorTemp(msg.state.temperature_pcb); setterRef.current.setRrSensorCur(msg.state.current_motor); } },
          { topic: "/r_left_wheel/sensors/core", messageType: "vesc_msgs/VescStateStamped", handler: (msg) => { setterRef.current.setRlSensorTemp(msg.state.temperature_pcb); setterRef.current.setRlSensorCur(msg.state.current_motor); } },
          { topic: "/f_left_wheel/sensors/core", messageType: "vesc_msgs/VescStateStamped", handler: (msg) => { setterRef.current.setFlSensorTemp(msg.state.temperature_pcb); setterRef.current.setFlSensorCur(msg.state.current_motor); } },
          { topic: "/f_right_wheel/sensors/core", messageType: "vesc_msgs/VescStateStamped", handler: (msg) => { setterRef.current.setFrSensorTemp(msg.state.temperature_pcb); setterRef.current.setFrSensorCur(msg.state.current_motor); } },
          { topic: "/adnav_driver/NavSatFix", messageType: "sensor_msgs/NavSatFix", handler: (msg) => { setterRef.current.setGpsMsg([msg.latitude, msg.longitude]); } },
          { topic: "/global_path", messageType: "nav_msgs/Path", handler: (msg) => { setterRef.current.setGlobalPathMsg({ pose: msg.poses, zone: msg.header.frame_id, seq: msg.header.seq }); } },
          { topic: "/curr_path", messageType: "nav_msgs/Path", handler: (msg) => { setterRef.current.setGeoFenceMsg({ pose: msg.poses, zone: msg.header.frame_id, seq: msg.header.seq }); } },
          { topic: "/odometry/filtered_map", messageType: "nav_msgs/Odometry", handler: (msg) => { setterRef.current.setOdomMsg(msg); } },
          { topic: "/odom_origin", messageType: "localization/TwoFloats", handler: (msg) => { setterRef.current.setOdomOriginMsg(msg); } },
          { topic: "/home_location", messageType: "localization/TwoFloats", handler: (msg) => { setterRef.current.setHomeLocationMsg(msg); } },
          { topic: "/adnav_driver/GNSSFix", messageType: "std_msgs/String", handler: (msg) => { setterRef.current.setGpsStatusMsg(msg.data); } },
          { topic: "/sensors/battery_soc", messageType: "std_msgs/Int32", handler: (msg) => { setterRef.current.setBatteryMsg(msg.data); } },
          { topic: "/sensors/pneumatic", messageType: "std_msgs/Int32", handler: (msg) => { setterRef.current.setPneumaticMsg(msg.data); } },
          { topic: "/sensors/ultra_sound", messageType: "std_msgs/Int32", handler: (msg) => { setterRef.current.setUltraSoundMsg(msg.data); } },
          { topic: "/estimated_time_completion", messageType: "std_msgs/Int16", handler: (msg) => { setterRef.current.setEtcMsg(msg.data); } },
          { topic: "/percentage_completed", messageType: "std_msgs/Int16", handler: (msg) => { setterRef.current.setProgressMsg(msg.data); } },
          { topic: "/sensors/singulations", messageType: "std_msgs/Float32MultiArray", handler: (msg) => { setterRef.current.setSingulation(msg.data); } },
          { topic: "/sensors/loadpins", messageType: "std_msgs/Float32MultiArray", handler: (msg) => { setterRef.current.setLoadPins(msg.data); } },
          { topic: "/sensors/populations", messageType: "std_msgs/Float32MultiArray", handler: (msg) => { setterRef.current.setPopulation(msg.data); } },
          { topic: "video_frames", messageType: "sensor_msgs/CompressedImage", handler: (msg) => { setterRef.current.setImage360("data:image/jpg;base64," + msg.data); } }
        ], []);
        
        useEffect(() => {
            if (!isConnected) return;

            let isMounted = true;
            
          const createdListeners = listeners.map(({ topic, messageType, handler }) => {
            const listener = createListener(topic, messageType, 1, "none", 1000);
            if (isMounted) {
                listener.subscribe(handler);
            }
            return listener;
          });

          return () => {
            isMounted = false;
            createdListeners.forEach(listener => {
              console.log(`Unsubscribe ${listener.name}`);
              listener.unsubscribe();
            });
          };
        }, [isConnected, createListener, listeners]);


    const handleIp = (event) => {
        console.log(event.target.value);
        changeUrl(event.target.value);
    };

    const handleVNC = (event) => {
        console.log("vnc");
    };

    useEffect(() => {
        const preMissionPublisher = createPublisher(
            "/pre_mission",
            "std_msgs/Int8"
        );
        const pubPreMissionMessage = (topic) => {
            if (isPreMissionLaunch) {
                const msg = createMessage({ data: 1 });
                topic.publish(msg);
            } else {
                const msg = createMessage({ data: 0 });
                topic.publish(msg);
            }
        };
        pubPreMissionMessage(preMissionPublisher);
    }, [isPreMissionLaunch]);

    useEffect(() => {
        const missionPublisher = createPublisher("/mission", "std_msgs/Int8");
        const missionMessage = (topic) => {
            if (isMissionLaunch) {
                const msg = createMessage({ data: 1 });
                topic.publish(msg);
            } else {
                const msg = createMessage({ data: 0 });
                topic.publish(msg);
            }
        };
        missionMessage(missionPublisher);
    }, [isMissionLaunch]);

    useEffect(() => {
        const toolBarService = createService("/bar_control", "sensors/bar");
        console.log('isToolBarDown', isToolBarDown)
        const toolbarService = (service) => {
            if (isToolBarDown === false) {
                const srv = createSrv({ toggleState: 2 });
                service.callService(srv, function (result) {
                    console.log(
                        "bar_control was called. " +
                            "result: " +
                            result.success
                    );
                });
            } else if (isToolBarDown === true) {
                const srv = createSrv({ toggleState: 1 });
                service.callService(srv, function (result) {
                    console.log(
                        "bar_control was called. " +
                            "result: " +
                            result.success
                    );
                });
            }
        };
        toolbarService(toolBarService);
    }, [isToolBarDown]);

    // vacuum
    useEffect(() => {
        const Service = createService("/vacuum_control", "sensors/vacuum");
        const vacService = (service) => {
            if (isVacuumOn === false) {
                const srv = createSrv({ switchVacuum: 1 });
                service.callService(srv, function (result) {
                    console.log(
                        "vacumm_control was called. " +
                            "result: " +
                            result.success
                    );
                });
            } else if (isVacuumOn === true) {
                const srv = createSrv({ switchVacuum: 0 });
                service.callService(srv, function (result) {
                    console.log(
                        "vacumm_control was called. " +
                            "result: " +
                            result.success
                    );
                });
            }
        };
        vacService(Service);
    }, [isVacuumOn]);

    // check vacuum status
    useEffect(() => {
       if (vacPrs < 1.5){
            setVacOffIndicator(true)
       } else if(vacPrs > 1.5){
        setVacOffIndicator(false)
       }
    }, [vacPrs]);

    // chack toolbar status
    useEffect(() => {
        console.log('bar angle: ', barAngle)
        if (barAngle > 10){
            setToolBarIndicatorDown(false)
        } else if(barAngle < 2){
            setToolBarIndicatorDown(true)
        }
     }, [barAngle]);
 

     
    //  steering
    useEffect(() => {
        const Service = createService("/steer_control", "sensors/steer");
        const steerService = (service) => {
            if (steer === 'skid') {
                const srv = createSrv({ toggleState: 1 });
                service.callService(srv, function (result) {
                    console.log(
                        "Skid steer was called. " +
                            "result: " +
                            result.success
                    );
                });
            } else if (steer === 'pivit') {
                const srv = createSrv({ toggleState: 2 });
                service.callService(srv, function (result) {
                    console.log(
                        "Pivit steer was called. " +
                            "result: " +
                            result.success
                    );
                });
            } else if (steer === 'transport') {
                const srv = createSrv({ toggleState: 3 });
                service.callService(srv, function (result) {
                    console.log(
                        "Transport steer was called. " +
                            "result: " +
                            result.success
                    );
                });
            }
        };
        steerService(Service);
    }, [steer]);



    useEffect(() => {
        const autonomousNavPublisher = createPublisher(
            "/start_nav",
            "std_msgs/Int16"
        );
        if (mounted.current) {
            // If component is mounted (not the first render)
            const msg = createMessage({ data: isAutonomousNavigation ? 1 : 0 });
            autonomousNavPublisher.publish(msg);
        } else {
            mounted.current = true; // Set mounted to true after the first render
        }
    }, [isAutonomousNavigation]);

    useEffect(() => {
        const pointAPublisher = createPublisher(
            "/select_point_a",
            "std_msgs/Int8"
        );
        const pointAMessage = (topic) => {
            if (pointASelected) {
                const msg = createMessage({ data: 1 });
                topic.publish(msg);
            } else {
                const msg = createMessage({ data: 0 });
                topic.publish(msg);
            }
        };
        pointAMessage(pointAPublisher);
    }, [pointASelected]);

    const handlePointA = () => {
        setPointASelected((prev) => !prev);
    };

    useEffect(() => {
        const pointBPublisher = createPublisher(
            "/select_point_b",
            "std_msgs/Int8"
        );
        const pointBMessage = (topic) => {
            if (pointBSelected) {
                const msg = createMessage({ data: 1 });
                topic.publish(msg);
            } else {
                const msg = createMessage({ data: 0 });
                topic.publish(msg);
            }
        };
        pointBMessage(pointBPublisher);
    }, [pointBSelected]);

    const handlePointB = () => {
        setPointBSelected((prev) => !prev);
    };

    // const handleAutonomousNavigation = () => {
    //     setIsAutonomousNavigation((prev) => !prev);
    // };

    const handleStartNavigation = () => {
        const autonomousNavigationPublisher = createPublisher("/start_nav", "std_msgs/Int8");
        // Create a message with data set to 1
        const msg = createMessage({ data: 1 });        
        // Publish the message
        autonomousNavigationPublisher.publish(msg);
        setIsAutonomousNavigation(true);
    };

    const handleStopNavigation = () => {
        const autonomousNavigationPublisher = createPublisher("/start_nav", "std_msgs/Int8");
        // Create a message with data set to 0
        const msg = createMessage({ data: 0 });
        // Publish the message
        autonomousNavigationPublisher.publish(msg);
        setIsAutonomousNavigation(false);
    };

    const handleMission = () => {
        setIsMissionLaunch((prev) => !prev);
    };

    const handlePreMission = () => {
        setIsPreMissionLaunch((prev) => !prev);
    };

    // const handleBrake = () => {
    //   console.log('break')
    //   setIsBraked((prev) => !prev);
    //   const AutonomousNavPublisher = createPublisher(
    //       "/start_nav_test",
    //       "std_msgs/Int8"
    //   );
    //   if (isBraked) {
    //     const msg = createMessage({ data: 0 });
    //     AutonomousNavPublisher.publish(msg);
    //   } else {
    //     const msg = createMessage({ data: 1 });
    //     AutonomousNavPublisher.publish(msg);
    //   }
    // };

    const handleToolBar = () => {
        console.log("clicked");
        // console.log("isToolBarDown: ", isToolBarDown);
        setIsToolBarDown((prev) => !prev);
    };

    const handleVacuum = () => {
        console.log("clicked");
        setIsVacuumOn((prev) => !prev);
    };

    const handleSteer = (event) => {
        console.log(event);
        setSteer(event);
    };

    const handleKillRos = () => {
        const killRosPublisher = createPublisher(
            "/kill_robot",
            "std_msgs/Int8"
        );
        const msg = createMessage({ data: 1 });
        killRosPublisher.publish(msg);
    };

    useEffect(() => {
        const setHomeService = createService("/set_home_pose", "SetHomePose");
        const homeService = (service) => {
            if (home) {
                const srv = createSrv({ request: true });
                service.callService(srv, function (result) {
                    console.log(
                        "set_home_pose was called. " +
                            "result: " +
                            result.success
                    );
                });
            }
        };
        homeService(setHomeService);
    }, [home]);

    const handleSetHome = () => {
        setHome((prev) => !prev);
    };

    useEffect(() => {
        const setHomeService = createService("/go_to_pose", "GoToPose");
        const homeService = (service) => {
            const srv = createSrv({ x: parseFloat(PoseX), y: parseFloat(PoseY) });
            service.callService(srv, function (result) {
                console.log("go_to_pose was called " + "result: ", result.sum);
            });
        };
        homeService(setHomeService);
    }, [randomPose]);

    const handlePose = (x, y) => {
        setPoseX(x);
        setPoseY(y);
        setRandomPose((prev) => !prev);
    };

    const handleRemoveABLines = () => {
        const removeABLinesPublisher = createPublisher(
            "/remove_ab",
            "std_msgs/Int8"
        );
        const msg = createMessage({ data: 1 });
        removeABLinesPublisher.publish(msg);
    };

    const handleComebackHome = (conservative) => {
        const comeBackHomeService = createService(
            "/return_home",
            "ReturnToHome"
        );
        let srv;
        const homeService = (service) => {
            if (conservative) {
                srv = createSrv({ mode_of_return: 0 });
            } else {
                srv = createSrv({ mode_of_return: 1 });
            }
            service.callService(srv, function (result) {
                console.log(
                    "return_home was called. " + "result: " + result.response
                );
            });
        };
        homeService(comeBackHomeService);
    };

    const handleKeyCode = (code) => {
        const keyTeleopPublisher = createPublisher(
          "input/teleop_key",
            "geometry_msgs/Twist"
        );
        const pubKeyeMessage = (topic) => {
            if (code === "KeyW") {
                const msg = createMessage({
                    linear: {
                        x: 1.5,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                });
                topic.publish(msg);
            } else if (code === "KeyS") {
                const msg = createMessage({
                    linear: {
                        x: -1.5,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                });
                topic.publish(msg);
            } else if (code === "KeyD") {
                const msg = createMessage({
                    linear: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: -1,
                    },
                });
                topic.publish(msg);
            } else if (code === "KeyA") {
                const msg = createMessage({
                    linear: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: 1,
                    },
                });
                topic.publish(msg);
            } else if (code === "KeyQ") {
                const msg = createMessage({
                    linear: {
                        x: 1,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: 0.5,
                    },
                });
                topic.publish(msg);
            } else if (code === "KeyE") {
                const msg = createMessage({
                    linear: {
                        x: 1,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: -0.5,
                    },
                });
                topic.publish(msg);
            } else if (code === "Space") {
                const msg = createMessage({
                    linear: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                    angular: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                });
                topic.publish(msg);
            }
        };
        pubKeyeMessage(keyTeleopPublisher);
    };

    const speed = useMemo(() => (
        gpsMsg.length > 1 && odomMsg.twist 
            ? Math.sqrt((odomMsg.twist.twist.linear.x * 2.23694)**2 + (odomMsg.twist.twist.linear.y * 2.23694)**2)
            : 0
    ), [gpsMsg, odomMsg]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    {/* <Sidebar isSidebar={isSidebar} /> */}
                    <main className="content">
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Dashboard
                                        zedObjects={zedObjects}
                                        image360={image360}
                                        image={image}
                                        handleKeyCode={handleKeyCode}
                                        handleComebackHome={handleComebackHome}
                                        handleRemoveABLines={
                                            handleRemoveABLines
                                        }
                                        handleSetHome={handleSetHome}
                                        handlePose={handlePose}
                                        home={home}
                                        handleKillRos={handleKillRos}
                                        pointB={pointBGpsMsg}
                                        pointA={pointAGpsMsg}
                                        pointBSelected={pointBSelected}
                                        handlePointB={handlePointB}
                                        pointASelected={pointASelected}
                                        handlePointA={handlePointA}
                                        isToolBarDown={isToolBarDown}
                                        toolBarIndicatorDown={toolBarIndicatorDown}
                                        isVacuumOn={isVacuumOn}
                                        handleToolBar={handleToolBar}
                                        handleVacuum={handleVacuum}
                                        handleSteer={handleSteer}
                                        isMissionLaunch={isMissionLaunch}
                                        handleMission={handleMission}
                                        isPreMissionLaunch={isPreMissionLaunch}
                                        handlePreMission={handlePreMission}
                                        isAutonomousNavigation={
                                            isAutonomousNavigation
                                        }
                                        // handleAutonomousNavigation={
                                        //     handleAutonomousNavigation
                                        // }
                                        handleStartNavigation={handleStartNavigation}
                                        handleStopNavigation={handleStopNavigation}
                                        // isBraked={isBraked}
                                        // handleBrake={handleBrake}
                                        handleIp={handleIp}
                                        handleVNC={handleVNC}
                                        isConnected={isConnected}
                                        toggleConnection={toggleConnection}
                                        location={gpsMsg}
                                        odom={odomMsg}
                                        odomOrigin={odomOriginMsg}
                                        homeLocation={homeLocationMsg}
                                        globalPath={globalPathMsg}
                                        geoFence={geoFenceMsg}
                                        temperature={
                                            isConnected && {
                                                RL: rlSensorTemp,
                                                RR: rrSensorTemp,
                                                FL: flSensorTemp,
                                                FR: frSensorTemp,
                                            }
                                        }
                                        current={
                                            isConnected && {
                                                RL: rlSensorCur,
                                                RR: rrSensorCur,
                                                FL: flSensorCur,
                                                FR: frSensorCur,
                                            }
                                        }
                                        barAngle = {barAngle}
                                        pneumatic = {pneumatic}
                                        vacPrs = {vacPrs}
                                        vacOffIndicator = {vacOffIndicator}
                                        batteryAux = {batteryAuxMsg}
                                        ultraSound={ultraSoundMsg}
                                        singulations={singulations}
                                        populations={populations}
                                        loadpins={loadPins}
                                        battery={batteryMsg}
                                        etc={etcMsg}
                                        progress={progressMsg}
                                        gpsStatus={gpsStatusMsg}
                                        speed={speed}
                                    />
                                }
                            />
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
  );
};

export default EchoTopic;
