import React, { useRef, useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../index.css";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

export default function KeyNav({ getKeyCode, isAutonomousNavigation }) {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  // const [input, setInput] = useState("");
  const keyboard = useRef();

  const handleKeyDown = (event) => {
    if (!isAutonomousNavigation) {
      getKeyCode(event.code);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isAutonomousNavigation]);

  return (
    <div>
      <Keyboard
        // style={{ background: "red" }}
        keyboardRef={(r) => (keyboard.current = r)}
        layout={{
          default: ["q w e", "a s d"],
          shift: ["Q W E", "A S D"],
        }}
        physicalKeyboardHighlight={isAutonomousNavigation ? false : true}
        theme={"hg-theme-default hg-layout-default myTheme"}
        display={{
          q: '<i class="fa-solid fa-q"></i>',
          w: '<i class="fa-solid fa-arrow-up"></i>',
          e: '<i class="fa-solid fa-e"></i>',
          a: '<i class="fa-solid fa-arrow-left"></i>',
          s: '<i class="fa-solid fa-arrow-down"></i>',
          d: '<i class="fa-solid fa-arrow-right"></i>',
        }}
      />
    </div>
  );
}
