import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker, Polyline} from '@react-google-maps/api';
import { parse } from '@fortawesome/fontawesome-svg-core';
import UtmLatLng from "utm-latlng";
import MapControl from "./MapControl";
import { Switch, FormControlLabel, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const containerStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
  };

const options_yellow = {
    strokeColor: "yellow",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 11,
};
const options = {
  strokeColor: "red",
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 11,
};

const track_options = {
  strokeColor: "green",
  strokeOpacity: 0.75,
  strokeWeight: 25,
  fillColor: "green",
  fillOpacity: 0.75,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 11,
};

let track_path = [];
let center_specified = false; 
let center = {};
let path = [];
let geoFence = [];
let homeLocation = []   

function MyComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ""
  })

   // Define the initial state for the map's center
   const initialCenter = {
    lat: parseFloat(props.location && props.location[0] ? props.location[0] : 42.0308),
    lng: parseFloat(props.location && props.location[1] ? props.location[1] : -93.6319),
  };

  // State to manage the map's center
  const [center, setCenter] = useState(initialCenter);

  // Update the map center when the robot's location changes
  useEffect(() => {
    if (props.location && props.location.length === 2) {
      const newCenter = {
        lat: parseFloat(props.location[0]),
        lng: parseFloat(props.location[1]),
      };
      setCenter(newCenter); // Update the center state
    }
  }, [props.location]); // Depend on props.location to trigger the update

    // console.log(props.homeLocation);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const heading = useRef(null);
    const [showABLine, setShowABLine] = useState(true);
    // const [ShowRobotInfo, setShowRobotInfo] = useState(false);
    const [showBoundaries, setShowBoundaries] = useState(true);
    const [showTrack, setShowTrack] = useState(false);

    const robotLat = props.location && props.location[0] ? parseFloat(props.location[0]) : parseFloat(42.0308);
    // const robotLat = parseFloat(props.location?[0]) ?? parseFloat(42.0308);
    // const robotLng = parseFloat(props.location?[1]) ?? parseFloat(-93.6319);
    const robotLng = props.location && props.location[1] ? parseFloat(props.location[1]) : parseFloat(-93.6319);

    const homeX = props.homeLocation && props.homeLocation.x ? parseFloat(props.homeLocation.x) : parseFloat(42.0308);
    // const homeX = parseFloat(props.homeLocation?.x) ?? parseFloat(42.0308);
    // const homeY = parseFloat(props.homeLocation?.y) ?? parseFloat(-93.6319);
    const homeY = props.homeLocation && props.homeLocation.y ? parseFloat(props.homeLocation.y) : parseFloat(-93.6319);

    // get heading from quaternion of odom
    const x = parseFloat(props.odom?.pose?.pose?.orientation?.x) ?? parseFloat(0);
    const y = parseFloat(props.odom?.pose?.pose?.orientation?.y) ?? parseFloat(0);
    const w = parseFloat(props.odom?.pose?.pose?.orientation?.z) ?? parseFloat(0);  
    const z = parseFloat(props.odom?.pose?.pose?.orientation?.w) ?? parseFloat(0);

    // specify center of map using the first location if it was not already specified
    // console.log(robotLat, robotLng, center_specified, center);
    if (!center_specified) {
    center.lat = robotLat;
    center.lng = robotLng;
    center_specified = true;
    }
    // make sure props.globalPath is not empty object
    // console.log(props.globalPath);
    if (props.globalPath && Object.keys(props.globalPath).length !== 0) {
        let path_length = props.globalPath.pose.length;
        for (let i = 0; i < path_length; i++) {
            let pose = props.globalPath.pose[i];
            // // convert UTM pose to lat/long
            let utmLatLng = new UtmLatLng();
            let utmLatLng_result = utmLatLng.convertUtmToLatLng(
                pose.pose.position.x + props.odomOrigin.x,
                pose.pose.position.y + props.odomOrigin.y,
                pose.header.frame_id.slice(0, 2), // zone number
                pose.header.frame_id.slice(2, 3) // zone letter
            );
            path.push({ lat: utmLatLng_result.lat, lng: utmLatLng_result.lng });
            if (path.length > path_length) {
                path.shift();
            }
        }
    }
    if (props.geoFence && Object.keys(props.geoFence).length !== 0) {
        let geoFence_length = props.geoFence.pose.length;
        for (let i = 0; i < geoFence_length; i++) {
            let geoFencePose = props.geoFence.pose[i];
            // convert UTM pose to lat/long
            let utmLatLng = new UtmLatLng();
            let utmLatLng_result = utmLatLng.convertUtmToLatLng(
                geoFencePose.pose.position.x + props.odomOrigin.x,
                geoFencePose.pose.position.y + props.odomOrigin.y,
                geoFencePose.header.frame_id.slice(0, 2), // zone number
                geoFencePose.header.frame_id.slice(2, 3) // zone letter
            );
            geoFence.push({
                lat: utmLatLng_result.lat,
                lng: utmLatLng_result.lng,
            });
            if (geoFence.length > geoFence_length) {
                geoFence.shift();
            }
        }
    }

    if (
        props.homeLocation && props.geoFence && Object.keys(props.homeLocation).length !== 0 &&
        Object.keys(props.geoFence).length !== 0
    ) {
        let geoFencePose = props.geoFence.pose[0];
        // // convert UTM pose to lat/long
        let utmLatLng = new UtmLatLng();
        let utmLatLng_result = utmLatLng.convertUtmToLatLng(
            homeX + props.odomOrigin.x,
            homeY + props.odomOrigin.y,
            geoFencePose.header.frame_id.slice(0, 2), // zone number
            geoFencePose.header.frame_id.slice(2, 3) // zone letter
        );
        homeLocation.push(utmLatLng_result.lat, utmLatLng_result.lng);
        // keep only the most recent home location
        if (homeLocation.length > 1) {
            homeLocation.shift();
        }
    }

    // convert quaternion to heading degrees
    const headingDeg = parseFloat(
        Math.atan2(2 * (w * z + x * y), 1 - 2 * (y * y + z * z)) *
            (180 / Math.PI)
    );
    const headingModified = headingDeg - 90;
    track_path = track_path.includes({ lat: robotLat, lng: robotLng })
        ? track_path
        : [...track_path, { lat: robotLat, lng: robotLng }].slice(-100);


let icon = {
    path: "M2,8 L5,2 L8,8 L5,6.5 L2,8",
    fillColor: "red",
    fillOpacity: 0.75,
    stroke: "black",
    strokeWeight: 3,
    scale: 10,
    backgroundColor: "black",
    scaledSize: { width: 100, height: 100 },
    anchor: { x: 5, y: 6 },
    rotation : headingModified,
    // rotation:
    //   speed < 0.9 // meter p second
    //     ? heading.current
    //     : rotation,
    // rotation: Math.random() * 180
};

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  function handleClickAB() {
    setShowABLine((prev) => !prev);
}

function handleClickTrack() {
    setShowTrack((prev) => !prev);
}

function handleClickBoundries() {
    setShowBoundaries((prev) => !prev);
}


  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        zoom={14}
        mapTypeId={"satellite"}
      >
        {props.location && (<Marker
        position={{
            lat: robotLat,
            lng: robotLng,
        }}
        options={{ icon: icon }}
        />)}
        {props.homeLocation.length !== 0 && (<Marker
            position={{
                lat: homeLocation[0],
                lng: homeLocation[1],
            }}
            options={{
                label: {
                    text: "H",
                    color: "#000",
                    fontSize: "25px",
                    fontWeight: "bold",
                },
            }}
        />)}
        {props.pointA && showABLine && (
            <Marker
                position={{
                    lat: parseFloat(props.pointA.latitude),
                    lng: parseFloat(props.pointA.longitude),
                }}
                options={{ label: "A" }}
            />
        )}

                    {props.pointB && showABLine && (
                        <Marker
                            position={{
                                lat: parseFloat(props.pointB.latitude),
                                lng: parseFloat(props.pointB.longitude),
                            }}
                            options={{ label: "B" }}
                        />
                    )}

                    {showABLine && (
                        <Polyline path={path} options={options_yellow} />
                    )}
                    {showBoundaries && (
                        <Polyline path={geoFence} options={options} />
                    )}
                    {showTrack && (
                        <Polyline path={track_path} options={track_options} />
                    )}

                    <MapControl
                    position={window.google.maps.ControlPosition.TOP_LEFT}
                        >
                        <div>
                            <FormControlLabel
                                sx={{
                                    backgroundColor: colors.blueAccent[600],
                                    borderRadius: "20px",
                                    mt: 1.25,
                                    ml: 1.25,
                                    pl: 0,
                                    pr: 1.25,
                                    minWidth: "230px",
                                }}
                                control={<Switch defaultChecked />}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: colors.grey[100],
                                        }}
                                        variant="h3"
                                    >
                                        DISPLAY AB LINES{" "}
                                    </Typography>
                                }
                                onChange={handleClickAB}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                sx={{
                                    backgroundColor: colors.blueAccent[600],
                                    borderRadius: "20px",
                                    mt: 0.5,
                                    ml: 1.25,
                                    pl: 0,
                                    pr: 1.25,
                                    minWidth: "230px",
                                }}
                                control={<Switch defaultChecked={false} />}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: colors.grey[100],
                                        }}
                                        variant="h3"
                                        color="textPrimary"
                                    >
                                        DISPLAY ROBOT TRACK
                                    </Typography>
                                }
                                onChange={handleClickTrack}
                            />
                        </div>

                        <div>
                            <FormControlLabel
                                sx={{
                                    backgroundColor: colors.blueAccent[600],
                                    borderRadius: "20px",
                                    mt: 0.5,
                                    ml: 1.25,
                                    pl: 0,
                                    pr: "10px",
                                    minWidth: "230px",
                                }}
                                control={<Switch defaultChecked />}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: colors.grey[100],
                                        }}
                                        variant="h3"
                                        color="textPrimary"
                                    >
                                        DISPLAY CURRENT PATH
                                    </Typography>
                                }
                                onChange={handleClickBoundries}
                            />
                        </div>
                    </MapControl>
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)