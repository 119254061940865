import React, { useContext, useEffect, useCallback } from "react";
import ROSLIB from "roslib";
import { ROSContext, ROSProvider } from "./ROSContext";
import PropTypes from "prop-types";

// ROS Hook that lets others use ROS websocket connection
// returns some useful functions & values
function useROS() {
  const [ros, setROS] = useContext(ROSContext);

  useEffect(() => {
    if (!ros.isConnected) {
      if (ros.autoconnect) {
        console.log("autoconnecting");
        handleConnect();
      }
    }
  }, [ros.autoconnect, ros.isConnected]);

  function checkConnection() {
    if (ros.ROS) {
      if (ros.isConnected) {
        if (!ros.ROSConfirmedConnected && ros.ROS.isConnected) {
          setROS((ros) => ({
            ...ros,
            ROSConfirmedConnected: ros.ROS.isConnected,
          }));
          console.log("Both react-ros and roslibjs have confirmed connection.");
        }
        // Once we have that "confirmation"  we need to continously check for good connection
        else if (ros.ROSConfirmedConnected && !ros.ROS.isConnected) {
          setROS((ros) => ({ ...ros, isConnected: false }));
          handleDisconnect();
        } else if (!ros.ROS.isConnected) {
          console.log(
            "React-ros has confirmed the connection, roslibjs has not yet."
          );
        }
      }
    } else {
      console.log("Initial connection not established yet");
    }
  }

  function toggleConnection() {
    if (ros.isConnected) {
      console.log("Disconnecting from ROS");
      handleDisconnect();
    } else if (!ros.isConnected) {
      console.log("Connecting to ROS");
      handleConnect();
    }
  }

  function toggleAutoconnect() {
    if (ros.autoconnect) {
      setROS((ros) => ({ ...ros, autoconnect: false }));
    } else if (!ros.autoconnect) {
      setROS((ros) => ({ ...ros, autoconnect: true }));
    }
  }

  function changeUrl(new_url) {
    console.log("Changing URL to:", new_url);
    setROS((ros) => ({ ...ros, url: new_url }));
  }

  function getTopics() {
    const topicsPromise = new Promise((resolve, reject) => {
      ros.ROS.getTopics(
        (topics) => {
          const topicList = topics.topics.map((topicName, i) => {
            return {
              path: topicName,
              msgType: topics.types[i],
              type: "topic",
            };
          });
          resolve({
            topics: topicList,
          });
          reject({
            topics: [],
          });
        },
        (message) => {
          console.error("Failed to get topic", message);
          ros.topics = [];
        }
      );
    });
    topicsPromise.then((topics) =>
      setROS((ros) => ({ ...ros, topics: topics.topics }))
    );
    return ros.topics;
  }

  function getServices() {
    const servicesPromise = new Promise((resolve, reject) => {
      ros.ROS.getServices(
        (services) => {
          const serviceList = services.map((serviceName) => {
            return {
              path: serviceName,
              type: "service",
            };
          });
          resolve({
            services: serviceList,
          });
          reject({
            services: [],
          });
        },
        (message) => {
          console.error("Failed to get services", message);
          ros.services = [];
        }
      );
    });
    servicesPromise.then((services) =>
      setROS((ros) => ({ ...ros, services: services.services }))
    );
    return ros.services;
  }

  const createListener = useCallback((
    topic,
    msg_type,
    to_queue,
    compression_type,
    throttle_rate
  ) => {
    var newListener = new ROSLIB.Topic({
      ros: ros.ROS,
      name: topic,
      messageType: msg_type,
      queue_length: to_queue,
      compression: compression_type,
      throttle_rate: throttle_rate,
    });

    for (var listener in ros.listeners) {
      if (newListener.name === ros.listeners[listener].name) {
        console.log(
          "Listener already available in ros.listeners[" + listener + "]"
        );
        return ros.listeners[listener];
      }
    }
    ros.listeners.push(newListener);
    console.log("Listener " + newListener.name + " created");
      return newListener;
    }, [ros.listeners, ros.ROS]);

  function createPublisher(topic, msg_type) {
    var newPublisher = new ROSLIB.Topic({
      ros: ros.ROS,
      name: topic,
      messageType: msg_type,
    });

    for (var publisher in ros.publishers) {
      if (newPublisher.name === ros.publishers[publisher].name) {
        console.log(
          "Publisher already available in ros.publishers[" + publisher + "]"
        );
        return ros.publishers[publisher];
      }
    }
    ros.publishers.push(newPublisher);
    console.log("Publisher " + newPublisher.name + " created");
    return newPublisher;
  }

  function createService(service, srv_type) { 
    var newService = new ROSLIB.Service({
        ros: ros.ROS,
        name: service,
        serviceType: srv_type,
    });
    for (var service in ros.services) {
        if (newService.name === ros.services[service].name) {
            console.log(
                "Service already available in ros.services[" +
                    service +
                    "]"
            );
            return ros.services[service];
        }
    }
    ros.services.push(newService);
    console.log("Service " + newService.name + " created");
    return newService;

  }
  
  function createSrv(srv) {
      var newService = new ROSLIB.ServiceRequest(srv);
      return newService;
  }

  function createMessage(msg) {
    var newMessage = new ROSLIB.Message(msg);
    return newMessage;
  }

  const handleConnect = useCallback(() => {
    try {
      console.log("Connecting to ROS at URL:", ros.url);
      ros.ROS.connect(ros.url);
      
      ros.ROS.on("connection", () => {
        console.log("Connected to ROS WebSocket server");
        setROS((prevRos) => ({ 
          ...prevRos, 
          isConnected: true, 
          ROSConfirmedConnected: false 
        }));
        getTopics();
        getServices();
      });

      ros.ROS.on("error", (error) => {
        console.error("ROS connection error:", error);
        setROS((prevRos) => ({ ...prevRos, isConnected: false }));
      });

      ros.ROS.on("close", () => {
        console.log("ROS connection closed");
        setROS((prevRos) => ({ 
          ...prevRos, 
          isConnected: false, 
          ROSConfirmedConnected: false 
        }));
      });
    } catch (e) {
      console.error("Exception during ROS connection:", e);
    }
  }, [ros.ROS, ros.url, setROS]);

  const handleDisconnect = () => {
    try {
      ros.ROS.close();
      setROS((ros) => ({ ...ros, isConnected: false }));
      setROS((ros) => ({ ...ros, topics: [] }));
      setROS((ros) => ({ ...ros, listeners: [] }));
      setROS((ros) => ({ ...ros, ROSConfirmedConnected: false }));
    } catch (e) {
      console.log(e);
    }
    console.log("Disconnected");
  };

  const removeAllListeners = () => {
    for (var mlistener in ros.listeners) {
      ros.listeners[mlistener].removeAllListeners();
    }
    setROS((ros) => ({ ...ros, listeners: [] }));
  };

  function removeListener(listener) {
    for (var mlistener in ros.listeners) {
      if (listener.name === ros.listeners[mlistener].name) {
        console.log("Listener: " + listener.name + " is removed");
        ros.listeners.splice(mlistener, 1);
        listener.removeAllListeners();
        return;
      }
    }
    console.log("Listener: " + listener + " is not a listener");
  }

  return {
      toggleConnection,
      changeUrl,
      getTopics,
      getServices,
      createListener,
      createPublisher,
      createMessage,
      createService,
      createSrv,
      toggleAutoconnect,
      removeAllListeners,
      removeListener,
      checkConnection,
      ros: ros.ROS,
      isConnected: ros.isConnected,
      autoconnect: ros.autoconnect,
      url: ros.url,
      topics: ros.topics,
      services: ros.services,
      listeners: ros.listeners,
      handleConnect,
  };
}

// ROS Component to manage ROS websocket connection and provide
// it to children props
function ROS(props) {
  return <ROSProvider>{props.children}</ROSProvider>;
}

ROS.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useROS, ROS };











