import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const LinearGauge = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const scaledValue =
        Math.abs(((props.value - props.minValue) / (props.maxValue - props.minValue)))*
        100;

    return (
        <div
            style={{
                width: "80%",
                backgroundColor: colors.primary[400],
                display: "flex",
                flexDirection: "row",
                alignItems: "center", // To vertically center the contents
            }}
        >
            {/* Uncomment the below section if you want to display the caption */}

            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    pr: "10px",
                }}
                variant="h5"
                color="text.primary"
            >
                {props.caption}
            </Typography>

            <LinearProgress
                variant="determinate"
                value={Math.round(scaledValue)}
                sx={{
                    "& .MuiLinearProgress-bar": {
                        backgroundColor:
                            Math.round(scaledValue) < 40
                                ? "green"
                                : Math.round(scaledValue) < 75
                                ? "yellow"
                                : "red",
                        borderRadius: "5px",
                    },
                    height: "10px",
                    borderRadius: "5px",
                    width: "100%",
                }}
            />

            {/* Uncomment the below section if you want to display the value */}

            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    pl: "10px",
                }}
                variant="h5"
                color={
                    Math.round(scaledValue) < 40
                        ? "text.primary"
                        : Math.round(scaledValue) < 75
                        ? "yellow"
                        : "red"
                }
            >
                {Math.abs(Math.round(props.value)) +
                    (props.type === "temperature" ? "°C" : "A")}
            </Typography>
        </div>
    );
};

export default LinearGauge;
