import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material';
import { tokens } from "../theme";

const FlexibleTable = ({ columns, data }) => {
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const getColor = (value, columnName, rowName) => {
  // Adjusted logic based on rowName
  if (rowName === 'Temperature (°C)') {
    // console.log('value:', value); 
    if (value > 75) return 'red'; 
    else if (value > 45) return 'yellow'; 
    else return 'white'; 
    }
    
  if (rowName === 'Current (A)') {
    if (value > 250) return 'red'; 
    else if (value > 150) return 'yellow'; 
    else return 'white';
  }

  if (rowName === 'Singulation (%)') {
    if (value === 0) return 'yellow';
    if (value === 1) return 'red';
    if (value === 2) return 'green';
    else return 'white'; }

  if (rowName === 'Down Force (lbs)') {
    return 'white';
  }

  if (rowName === 'Population (K)') {
    return 'white';
  }

  return 'white'; 
};

  const displayValue = (value) => {
    // Check if the value is numeric
    const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);
    return isNumeric ? Math.abs(Math.round(value)) : value;
  };


  return (
    <TableContainer align="center" >
      <Table size="small" sx={{maxWidth: 500}}>
        <TableHead >
          <TableRow >
            {columns.map((column) => (
              <TableCell key={column.id} sx={{ padding: '10px ', fontSize: '20px', fontWeight:'bold'}} >{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody >
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex} >
              {columns.map((column) => (
                <TableCell key={column.id} align="center" sx={{ padding: '10px 20px 10px 0px' , fontSize: '20px', fontWeight:'bold', color: getColor(row[column.id], column.id, row.value)}} >
                  {displayValue(row[column.id])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default FlexibleTable;