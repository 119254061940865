import * as React from "react";
import { Box, Typography } from "@mui/material";

export default function StatusWithLabel({ ...props }) {

    let Text;
    if (props.type === "ETC") {
        Text = `${Math.abs(props.text)} Minutes`;
    }
    else if (props.type === "GPS") {
        Text = props.text;
    }
    else if (props.type === "PLANTING STATUS") {
        Text = props.text;
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                p: "5px 5px",
                m: "0px 10px",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    display: "inline-flex",
                    background: props.color,
                    p: "20px 80px",
                    borderRadius: "10px",
                }}
            >
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                       {
                        props.type === "ETC" ? (
                            props.text === 0 ? (
                                <i
                                    className="fa-solid fa-spinner fa-bounce fa-2xl"
                                    style={{ marginLeft: "15px" }}
                                ></i>
                            ) : (
                                <i
                                    className="fa-solid fa-spinner fa-spin fa-2xl"
                                    style={{ marginLeft: "15px" }}
                                ></i>
                            )
                        ) : props.type === "GPS" ? (
                            props.text === "RTK Fixed" ? (
                            <i
                                className="fa-solid fa-map-location-dot fa-beat-fade fa-2xl"
                                style={{ marginLeft: "15px" }}
                            ></i>
                        ) : (
                            <i
                                className="fa-solid fa-map-location-dot fa-bounce fa-2xl"
                                style={{ marginLeft: "15px" }}
                            ></i>
                        )) : props.type === "PLANTING STATUS" ? (
                            props.text === "PLANTING" ? (
                            <i
                                className="fa-solid fa-seedling fa-beat-fade fa-2xl"
                                style={{ marginLeft: "15px" }}
                            ></i>
                        ) : (
                            <i
                                className="fa-solid fa-seedling fa-bounce fa-2xl"
                                style={{ marginLeft: "15px" }}
                            ></i>
                        )): null
                        }
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            padding: "5px 5px",
                            margin: "5px",
                            minWidth: "120px",
                        }}
                        align="center"
                        // variant="h5"
                        component="div"
                        color="black"
                    >
                        {Text}
                    </Typography>
                </Box>
            </Box>
         </Box>
    );
}
