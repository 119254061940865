import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import ROSLIB from "roslib";

const rosObj = {
  ROS: new ROSLIB.Ros(),
  url: "",
  isConnected: false,
  ROSConfirmedConnected: false,
  autoconnect: false,
  topics: [],
  services: [],
  listeners: [],
  publishers: [],
};

const ROSContext = createContext([{}, () => {}]);

const ROSProvider = (props) => {
  const [ros, setROS] = useState(rosObj);
  return (
    <ROSContext.Provider value={[ros, setROS]}>
      {props.children}
    </ROSContext.Provider>
  );
};

ROSProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ROSContext, ROSProvider };
